const approved = [
/*   {
    name: "Samantha Corrêia",
    course: "Geografia",
    college: "UDESC",
    photo: "samantha.png",
    text: `A minha experiência com o Pró foi muito boa. Já fiz outros cursinhos e nenhum foi como esse. Os professores são ótimos e sempre acessíveis, o ambiente saudável, com pessoas que se importam com o bem estar dos alunos, faz a gente se sentir em casa. O atendimento psicológico me ajudou bastante nos momentos que eu achei que não ia conseguir.`
  },
  {
    name: "Beatriz Feltrin",
    course: "Nutrição",
    college: "UFSC",
    photo: "beatriz.png",
    text: `Minha aprovação na UFSC foi fruto de um trabalho impecável da equipe Cursinho Pró.
    Com eles, aprendi que eu tinha a capacidade de passar numa universidade, mas com a ajuda dos professores e de toda a equipe, tudo se tornou mais fácil.
    No Pró, fiz amigos e conheci professores incríveis que até hoje são inspiração para mim. Minha eterna gratidão`
  },
  {
    name: "João V. Oliveira",
    course: "Sistemas de Informação",
    college: "UFSC",
    photo: "joao.png",
    text: `O PRÓ foi sem dúvidas a melhor escolha que fiz nesses tempos difíceis que vivemos. A equipe conta com pessoas que além de grandes profissionais, são também ótimas pessoas. Tive todo o apoio necessário para conseguir conquistar o meu objetivo e sou eternamente grato pelo serviço! #FamilíaPRÓ`
  },
  {
    name: "Camila Puntel",
    course: "Medicina",
    college: "UFFS",
    photo: "camila.png",
    text: `A minha experiência com o Pró apesar de curta, foi muito marcante e de muito aprendizado. Os professores são excepcionais e a equipe é muito acolhedora e atenciosa. É com certeza um cursinho que possui um diferencial. Valeu muito a pena ter feito parte disso e agradeço por todo o carinho que recebi durante esse tempo, vocês são demais!  #FamíliaPRÓ`
  },
  {
    name: "Luiza Basto",
    course: "Engenharia Petróleo",
    college: "UFSC",
    photo: "luiza.png",
    text: `Muito obrigada! Só tenho a agradecer aos professores e a toda equipe do pró que sempre estiveram apoiando e dispostos a ajudar. Com certeza esse apoio fez toda diferença para que eu conseguisse minha aprovação, fico muito feliz de ter sido parte dessa família!`
  },
  {
    name: "Laura Losso",
    course: "Medicina",
    college: "UNC",
    photo: "laura.png",
    text: `Estou muito feliz com a aprovação! Foi fruto de um semestre bem intenso, de muito estudo e emoção. Com certeza o PRÓ me ajudou bastante nessa trajetória, o Semiextensivo foi perfeito pra relembrar os conteúdos, e o apoio dos professores e dos colegas foi essencial. Agora rumo à federal! #FamilíaPRÓ`
  },  */
  {
    name: "Amanda Teixeira",
    course: "Biologia",
    college: "UFSC",
    photo: "amanda.png",
    text: `Minha experiência com o Pró Floripa não podia ser chamada de outra forma se não como minha segunda família. No Pró eu encontrei conforto, sorrisos e principalmente apoio nessa jornada que não foi nem um pouco fácil. 
    Sou eternamente grata por todo apoio e memórias criadas com todos que estão presentes na família Pró, se não fosse por cada um participando dessa etapa esse sonho não teria acontecido.`
  },
  {
    name: "Yasmin Pires",
    course: "Medicina",
    college: "UFSC",
    photo: "yasmin.png",
    text: `Minha experiência no Pró foi excelente e, com certeza, fundamental para minha aprovação. Toda a equipe foi muito atenciosa e dedicada desde o primeiro dia. Só tenho a agradecer por todo o carinho e toda a preocupação da Família Pró durante a minha jornada até a aprovação. Muito obrigada!`
  },
  {
    name: "Ana Santin",
    course: "Engenharia Mêcanica",
    college: "UFSC",
    photo: "anasantin.png",
    text: `Minha experiência com o Pró Floripa foi sensacional, professores qualificados que se importam com o aprendizado dos alunos, profissionais sempre prontos a ajudar quando necessário. Quando entrei não entendi o porque de família Pró, hoje entendo o porque obrigada por tudo Pró Floripa.`
  },
  {
    name: "Isabelle Marques",
    course: "Direito",
    college: "UFSC",
    photo: "isabelle.png",
    text: `Eu sou extremamente grata ao Pró por tudo que ele me trouxe, além da aprovação no curso que eu tanto desejava e na faculdade que eu queria, ele me fez conquistar amizades incríveis q vou levar pra vida, além de ter aula com os melhores professores! A família Pró com crtz vai ter minha eterna gratidão!`
  },
  {
    name: "Lívia Cordeiro",
    course: "Odontologia",
    college: "UFSC",
    photo: "livia.png",
    text: `Passei 75% do meu ensino médio em casa devido a pandemia e meu ensino ficou absurdamente desfalcado, sem contar o fato de que eu não tive aula de matemática durante o ensino médio inteiro. Fiz o Pró e o último ano do ensino médio juntos, mas com certeza não me arrependo da minha escolha, a qual me garantiu a tão sonhada vaga na federal!`
  },
  {
    name: "Gustavo Kube",
    course: "Geologia",
    college: "UFSC",
    photo: "gustavo.png",
    text: `O Pró foi uma luz pra eu não desistir de trilhar esse caminho pra faculdade. Fez com que eu encontrasse novos amigos que me apoiaram desde o início e me mostraram que eu não estava sozinho. Além dos professores e profissionais fantásticos que sempre estiveram disponíveis pra me auxiliar.`
  },
  {
    name: "Luan Pinheiro",
    course: "Ciências Econômicas",
    college: "UFSC",
    photo: "luan.png",
    text: `Quando cheguei para estudar no Pró, eu estava a mais de um ano e meio sem estudar material de vestibular, sinceramente achava que não conseguiria passar, e com apenas seis meses de estudos e muito trabalho, e um apoio imenso do Pró, conseguirmos a minha tão sonhada aprovação, e mesmo vido de outro Estado os professores do pró conseguiram me acolher tão bem que me deixou bem mais tranquilo nos estudos.`
  },
]

export default approved;