import React from 'react';

import About from '../components/Home/About';
import Approved from '../components/Home/Approved';
import Banner from '../components/Home/Banner';
import Content from '../components/Home/Content';
import Courses from '../components/Home/Courses';
import Location from '../components/Home/Location';
import Teachers from '../components/Home/Teachers';
import Work from '../components/Home/Work';
import Layout from '../components/Layout';

const Index = () => {
  return (
    <Layout>
      <Banner />
      <Content />
      <About />
      <svg className="relative top-0" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
        <path className="text-primary fill-current" d="M0,50 Q320,100 640,50 T1280,50 T1920,50 T2560,50 L2560,100 L0,100 Z"></path>
      </svg>
      <Courses />
      <svg className="relative top-0" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0" style={{ transform: "scaleY(-1)" }}>
        <path className="text-primary fill-current" d="M0,50 Q320,100 640,50 T1280,50 T1920,50 T2560,50 L2560,100 L0,100 Z" ></path>
      </svg>
      <Teachers />
      <Approved />
      <Work />
      <Location />
    </Layout>
    /* 29/05 */
  )
}

export default Index