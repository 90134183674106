import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Slider from 'react-slick';

import approved from '../../../data/approved';
import about from '../../../images/svg/about.svg';
import star from '../../../images/svg/star.svg';
import trophy from '../../../images/svg/trophy.svg';

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  arrows: false,
  autoplay: true,
  swipeToSlide: true,
  autoplaySpeed: 5000,
  pauseOnFocus: true,
  pauseOnHover: true,
  cssEase: "linear"
};

const Approved = () => {

  const approvedImages = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: {dir: {regex: "/images/approved/"}}) {
          edges {
            node {
              name,
              base,
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  height: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    `
  )

  const images = approvedImages.images.edges.map(({ node }) => node);

  const getStudentImage = (student) => {
    const image = images.find(image => image.base === student?.photo);
    return image;
  }

  return (
    <div id="approved" className="py-15 w-full relative overflow-hidden">
      <div className="text-center md:px-50 text-primary text-4xl font-bold mb-10 raleway">
        Nosso time de aprovados
      </div>
{/*       <div className="text-center md:px-50 text-gray-600 text-3xl font-semibold leading-6 mb-10">
        Confira o que nossos aprovados acharam da sua experiência na família Cursinho Pró! Venha fazer parte você também!
      </div> */}

      <div className="w-full overflow-hidden z-99">
        {/* Desktop */}
        <div className="hidden md:block px-20">
          <Slider {...settings}>
            {approved.map((student, index) => (
              <div key={index} className="p-5 z-999">
                <div className="relative grid grid-cols-3 rounded-3xl bg-primary">
                  <div className="col-span-1 max-h-full">
                    <GatsbyImage imgClassName="max-h-full" image={getImage(getStudentImage(student))} alt={getStudentImage(student)?.base} />
                  </div>
                  <div className="col-span-2">
                    <div className='bg-accent-500 mt-10 px-5 py-2'>
                      <div className="text-primary text-3xl font-bold mb-2">{student?.name}</div>
                      <div className="inline-flex">
                        <img className="mr-2 text-white" src={trophy} alt="Troféu" />
                        <span className="text-2xl font-semibold leading-5 text-white">{student?.course} ({student?.college})</span>
                      </div>
                    </div>
                    
                    <p className=" p-5 italic text-white text-justify">
                      "{student?.text}"
                      
                    </p>
                    <img className='absolute overflow-hidden bottom-3 md:max-h-full max-w-full right-3 z-100' src={star} alt="star" />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* mobile */}
        <div className="md:hidden">
          <Slider {...settings} slidesToShow={1}>
            {approved.map((student, index) => (
              <div key={index} className="p-5 z-999">
              <div className="grid grid-cols-3 rounded-3xl bg-primary">
                <div className="col-span-1 max-h-full">
                  <GatsbyImage imgClassName="max-h-full" image={getImage(getStudentImage(student))} alt={getStudentImage(student)?.base} />
                </div>
                <div className="col-span-2">
                  <div className='bg-accent-500 mt-10 px-5 py-2'>
                    <div className="text-primary text-3xl font-bold mb-2">{student?.name}</div>
                    <div className="inline-flex">
                      <img className="mr-2 text-white" src={trophy} alt="Troféu" />
                      <span className="text-2xl font-semibold leading-5 text-white">{student?.course} ({student?.college})</span>
                    </div>
                  </div>
                  <p className="p-5 italic text-white text-justify">
                    "{student?.text}"
                    <img className="absolute overflow-hidden bottom-3 md:max-h-full max-w-full right-3 z-100" src={star} alt="star" />
                  </p>
                </div>
              </div>
            </div>
            ))}
          </Slider>
        </div>
      </div>

      <img className="absolute overflow-hidden top-5 md:max-h-full max-w-full left-3 -z-1" src={about} alt="sobre" />
    </div>
  )
}

export default Approved
