import { Link } from 'gatsby'
import React from 'react'

const Work = () => {
  return (
    <div id="approved" className="py-15 px-5 w-full relative text-center bg-gray-100">
      <div className="text-center md:px-50 text-primary text-4xl font-bold mb-10 raleway">
        Quer trabalhar com a gente?
      </div>

      <Link
        to="/work"
        className='w-full md:w-auto cursor-pointer transform uppercase bottom-3 mt-5 bg-accent p-4 md:px-10 rounded-xl text-2xl text-primary font-bold overflow-hidden'>
        Preencha o formulário
      </Link>
    </div>
  )
}

export default Work
