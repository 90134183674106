import React from 'react';

import about from '../../../images/svg/about.svg';

const About = () => {
  return (
    <div id="about" className="relative py-15 w-full overflow-hidden">
      <div className="text-4xl font-bold text-primary text-center mb-15 raleway">Números que falam por sí</div>

      <div className="flex flex-col md:flex-row px-10 md:px-60 gap-10">
        <div className="text-center flex-1 bg-primary rounded-xl p-10" >
          <div className="text-7xl font-bold text-accent roboto">
            21
          </div>
          <div className="text-lg font-semibold text-white">
            Anos de experiência, ajudando os estudantes a alcançarem seus sonhos e objetivos
          </div>
        </div>
        <div className="text-center flex-1 bg-primary rounded-xl p-10">
          <div className="text-7xl font-bold text-accent roboto">
            67%
          </div>
          <div className="text-lg font-semibold text-white">
            Dos alunos do Pró foram aprovados
            nos últimos vestibulares
          </div>
        </div>
        <div className="text-center flex-1 bg-primary rounded-xl p-10">
          <div className="text-7xl font-bold text-accent roboto">
            +6.100
          </div>
          <div className="text-lg font-semibold text-white">
            
            Número de Alunos que já ajudamos a ingressar nas principais Universidades do Brasil
          </div>
        </div>
      </div>

      <div className="text-center pt-10">
        <a
          href="#courses"
          className='hvr-grow cursor-pointer bottom-3 mx-auto bg-accent hover:bg-accent-600 text-primary py-2 px-10 rounded-2xl text-lg font-bold overflow-hidden'>
          Conheça nossa história
        </a>
      </div>

      <img className="absolute overflow-hidden top-5 md:max-h-full max-w-full left-3" src={about} alt="sobre"/>

    </div>
  )
}

export default About
