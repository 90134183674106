import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import teachers from '../../../data/teachers';

const Teachers = () => {

  const teacherImages = useStaticQuery(
    graphql`
        query {
          images: allFile(filter: {dir: {regex: "/images/teachers/"}}) {
            edges {
              node {
                name,
                base,
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      `
  )

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };

  const settingsMobile = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const images = teacherImages.images.edges.map(({ node }) => node);

  const getTeacherImage = (teacher) => {
    const image = images.find(image => image.base === teacher.image);
    return image;
  }

  return (
    <div id="teachers" className="px-5 py-10 bg-gray-100 w-full">
      <div className="text-center md:px-50 text-primary text-4xl font-bold mb-10 raleway">
        Conheça nossos professores
      </div>
      <div className="text-center md:px-50 text-gray-600 text-3xl font-semibold leading-6 mb-10">
        Temos a melhor equipe de professores da Grande Florianópolis, com capacitação e experiência para te acompanhar nessa caminhada.
      </div>

      {/* desktop */}
      <div className="hidden md:block px-10">
        <Slider {...settings}>
          {teachers.map((teacher, index) => (
            <div className='' key={index}>
              <div className="shadow-md bg-white rounded-2xl mx-3 md:mx-13 my-5 pb-5 pt-2 text-center" key={index}>
                <GatsbyImage imgClassName="content-center" image={getImage(getTeacherImage(teacher))} alt={getTeacherImage(teacher)?.base} />
                <div className="mt-5 text-3xl text-primary font-bold">{teacher.name}</div>
                <div className="text-xl text-gray-500 font-bold">{teacher.discipline}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* mobile */}
      <div className="md:hidden px-10">
        <Slider {...settingsMobile}>
          {teachers.map((teacher, index) => (
            <div className='' key={index}>
              <div className="shadow-md bg-white rounded-2xl mx-3 md:mx-13 my-5 pb-5 pt-2 text-center" key={index}>
                <GatsbyImage imgClassName="content-center" image={getImage(getTeacherImage(teacher))} alt={getTeacherImage(teacher)?.base} />
                <div className="mt-5 text-3xl text-primary font-bold">{teacher.name}</div>
                <div className="text-xl text-gray-500 font-bold">{teacher.discipline}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

    </div >
  )
}

export default Teachers
