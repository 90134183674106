const teachers = [
  {
    name: 'Antônio',
    discipline: 'Química',
    image: 'antonio-quimica.png',
  },
  {
    name: 'Bruno Anderson',
    discipline: 'História',
    image: 'bruno-historia.png',
  },
  {
    name: 'Chico',
    discipline: 'Espanhol',
    image: 'chico-espanhol.png',
  },
  {
    name: 'Gilmarina',
    discipline: 'Redação',
    image: 'gilmarina-redacao.png',
  },
  {
    name: 'Jerônimo',
    discipline: 'Filosofia e Sociologia',
    image: 'jeronimo-filosofia.png',
  },
  {
    name: 'Josemir',
    discipline: 'Geografia',
    image: 'josemir-geografia.png',
  },
  {
    name: 'Leandro',
    discipline: 'Biologia',
    image: 'leandro-biologia.png',
  },
  {
    name: 'Lucas Feltz',
    discipline: 'Biologia',
    image: 'lucas-biologia.png',
  },
  {
    name: 'Mara',
    discipline: 'Gramática',
    image: 'mara-gramatica.png',
  },
  {
    name: 'Naza',
    discipline: 'Matemática',
    image: 'naza-matematica.png',
  },
  {
    name: 'Orestes',
    discipline: 'Matemática',
    image: 'orestes-matematica.png',
  },
  {
    name: 'Plaça',
    discipline: 'Física e Matemática',
    image: 'placa-fisica.png',
  },
  {
    name: 'Rave',
    discipline: 'Redação',
    image: 'rave-redacao.png',
  },
  {
    name: 'Sérgio',
    discipline: 'Literatura',
    image: 'sergio-literatura.png',
  },
  {
    name: 'Thamilis',
    discipline: 'Química',
    image: 'thamilis-quimica.png',
  },
  {
    name: 'Vinícius',
    discipline: 'Matemática',
    image: 'vinicius-matematica.png',
  }
  
];

export default teachers;
