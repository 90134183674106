import React from 'react';
import styled from 'styled-components';

import wavesSvg from '../../../images/svg/simulado-ondas.svg';
import simuladoSvg from '../../../images/svg/simulado.svg';
import simuladoLogoSvg from '../../../images/svg/logo-simulado.svg';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const BannerContainer = styled.div`
  min-height: calc(100vh - 56px);
  width: 100%;
  background-color: #ffdd00;
`;

export const BannerSimuladoBolsas2024 = () => {

  const data = useStaticQuery(
    graphql`
      query {
        img: file(relativePath: { eq: "simulado-pessoas.png",  }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF],
              quality: 100,
              width: 900,
            )
          }
        }
      }
    `);

  const pessoasImg = getImage(data.img.childImageSharp.gatsbyImageData);

  return (
    <BannerContainer className="w-full md:px-15 px-5 py-5 relative z-99 raleway text-center">
      <img className="absolute left-0 top-0 w-80" src={wavesSvg} alt="Cursinho Pró" />
      <img className="absolute hidden md:block left-40 top-10 w-3/6 max-w-full" src={simuladoSvg} alt="Cursinho Pró" />
      <img className="absolute right-0 top-30 md:top-10 md:w-2/5 w-full max-w-full" src={simuladoLogoSvg} alt="Cursinho Pró" />
      <div className="absolute hidden md:flex left-0 -bottom-10 -z-1">
        <GatsbyImage className='h-4/5' image={pessoasImg} alt="img" />
      </div>
      <a href='https://www.sympla.com.br/simulado-de-bolsas-20242025-cursinho-pro__2654355' target='_blank' className='absolute text-4xl py-1 text-white font-bold bottom-20 left-0 right-0 mx-auto md:mx-0 md:right-10 md:left-auto md:bottom-50 bg-warn hover:bg-warn-700 rounded-lg max-w-full md:w-96 w-4/5 hvr-grow'>
        Inscreva-se já!
      </a>
    </BannerContainer>
  );
}
