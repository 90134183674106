import React from 'react';
import styled from 'styled-components';

import raioxTextSvg from '../../../images/svg/raiox-text.svg';
import raioXLogo from '../../../images/svg/logo-raiox.svg';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const BannerContainer = styled.div`
  min-height: calc(100vh - 56px);
  width: 100%;
  background-color: #000;
`;

export const BannerRaioX2024 = () => {

  const openLink = (link) => {
    window.open(link, '_blank');
  }

  const data = useStaticQuery(
    graphql`
      query {
        img: file(relativePath: { eq: "raiox.png",  }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF],
              quality: 100,
            )
          }
        }
      }
    `);

  const raioxImg = getImage(data.img.childImageSharp.gatsbyImageData);

  return (
    <BannerContainer className="w-full md:px-15 relative z-99 raleway text-center">
      <img className="absolute hidden md:block right-0 top-10 bottom-0 my-auto" src={raioxTextSvg} alt="Cursinho Pró" />
      <div className='md:absolute pt-10 md:pt-0 right-60 top-10 w-full md:w-auto md:h-4/5'>
        <img src={raioXLogo} alt="Cursinho Pró" />
        <div className='bottom-10 md:-bottom-20 sm:-bottom-30 w-full text-center'>
          <button
            className='text-2xl md:text-3xl py-4 text-white font-bold bg-primary-400 hover:bg-primary-700 rounded-lg w-4/5 hvr-grow mb-10 mt-5'
            onClick={() => openLink('https://www.sympla.com.br/evento/aulao-enem-palhoca-cursinho-pro-2024-prepare-se-para-detonar-na-prova/2641250')}>
            Aulão ENEM Palhoça - 19/10
          </button>
          <button
            className='text-2xl md:text-3xl py-4 text-white font-bold bg-primary-400 hover:bg-primary-700 rounded-lg w-4/5 hvr-grow mb-10 mt-5'
            onClick={()=>openLink('https://www.sympla.com.br/evento/aulao-enem-floripa-cursinho-pro-2024-prepare-se-para-detonar-na-prova/2641246')}>
            Aulão ENEM Floripa - 26/10
          </button>
        </div>
      </div>
      <div className="absolute hidden md:flex left-0 bottom-0 -z-1">
        <GatsbyImage image={raioxImg} alt="img" />
      </div>
    </BannerContainer>
  );
}
